import "./index.css";
import SearchFilter from "./minicomponent/SearchFilter";
import Box from '@mui/material/Box';

import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import { Carousel } from "react-bootstrap";
import Bgvedio from "../../../images/bgvedio.mp4"
import Banner1 from "../../../../src/images/upcoming/banner1.png"
import Banner2 from "../../../../src/images/upcoming/banner2.png"
import Banner3 from "../../../../src/images/upcoming/banner3.png"
import Banner4 from "../../../../src/images/upcoming/Banner4.png"
import { Typography, Card, Grid } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { resetWarningCache, Tick } from "react-crude-animated-tick";

// import Carousel from 'react-bootstrap/Carousel';









const BannerMapper = [
  {
    // text: "Gate",  1
    image: Banner1,
    text: "Witness the Magical view of the",
    // para: " Majestic Address",
    // subpara: "3.5 & 4.5 BHK Luxurious Apartments"
  },
  {
    // text: "Gate",  1
    image: Banner2,
   
  },
  {
    // text: "Gate",  1
    image: Banner3,
    text: "Witness the Magical view of the ",

  },
  {
    // text: "Gate",  1
    image: Banner4,
    text: "Witness the Magical view of the ",
 
  },



];

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}
  const Banner = () => {
    const [dimensions, setDimensions] = React.useState({ 
      height: window.innerHeight,
      width: window.innerWidth
    })



    
    
  const [showModal, setShowModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const [number, setNumber] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [name, setName] = useState(" ");

  const submitted = () => {
    setSubmit(true);
  }

  
  const customStyles2 = {
    content: {
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "15px",
      height: "300px",
      zIndex: "200",
    },
  };
  async function handleSubmit(name, email, number) {
    // e.preventDefault();
    try {
      let res = await fetch(
        "https://archid-backend-tekq2uwgbq-el.a.run.app/book_appoint",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "Application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            phoneNo: number,
          }),
        }
      );
      let resJson = await res.json();
      console.log(resJson)
      if (resJson.status) {
        // setNumber("");
        // setEmail("");
        // setRequirement("");
      } else {
        // setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid>


      
      <div>
      
        <Carousel pause={true} indicators={true} >
          {BannerMapper?.map((el, idx) => {

            // else {
            return (
              <Carousel.Item key={idx} interval={3000} >
                <div
                  className="banner-background"
                  style={{
                    backgroundImage: `url(${el?.image})`,
                  }}
                >

                </div>
              </Carousel.Item>
            );

          })}
        </Carousel>

      </div>
   


      <div className="upper-text">
        <span className="toptext-1" >  Witness the Magical view of the<br />
          Majestic Address</span>
        <span className="toptext-2">3.5 & 4.5 BHK Luxurious Apartments</span>
      </div>


{/* bookyour appointment code here desktop main */}

      <div className="cont" style={{marginTop: "0%"}}>
        <Card style={{ width: "100%", padding:"30px", backgroundColor: "#BD8E38", borderRadius: "10px"}}>
    
          <Typography style={{ fontSize: "28px", color: '#0A253A', marginTop: "6%",textAlign:"start",marginLeft:"7%"}}>
            BOOK YOUR<br />
            APPOINTMENT
          </Typography>
        <div style={{ }}>
          <Typography  placeholder="Enter Your Name" style={{ marginRight:"70%",fontSize: "18px", color: 'white', marginTop: "4%", fontFamily:" Poppins" }}>
            Name
          </Typography>
      
          <FormControl   sx={{
            '& > :not(style)': { width: '223px', height: '33px' },
          }}>

            <OutlinedInput
               id="input"
              placeholder=" Enter Your Name"
              // label="mmm"
              type="text"
              value={name}
              style={{fontSize:16,color:"white"}}
              onChange={(e) => setName(e.target.value)}

            />
          </FormControl>
          </div>
          <Typography style={{ marginRight:"70%",fontSize: "18px", color: 'white', marginTop: "4%", fontFamily:" Poppins"  }}>
            Email
          </Typography>
          <FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px' },
          }}>
            <OutlinedInput
            id="input"
              placeholder="  Enter Your mail Id"
              value={email}
              type="text"
              style={{fontSize:16,color:"white"}}

              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Typography style={{marginRight:"60%", fontSize: "18px", color: 'white', marginTop: "4%", fontFamily:" Poppins" }}>
            Phone No
          </Typography>
          <FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px' },
          }}>
            <OutlinedInput
            id="input"
              placeholder="  Enter Your Phone no"
              type="number"
              style={{fontSize:16}}

              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </FormControl>
          <Button 
          style={{ width: '223px', height: '33px', marginTop: "6%", marginLeft: "1%", textTransform:" capitalize",backgroundColor: "#0A253A", fontSize: "16px" ,fontFamily:" Poppins"}} variant="contained"
          onClick={() => {
            if (

              name !== "" &&
              number !== "" &&
              email !== "" &&
              email.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              ) &&
              number.match(/^\d{10}$/)
            ) {
              handleSubmit(name, number, email)
              setSubmit(true);
              setModal(false);
              setNumber("");
              setName("");
              setEmail("");
            }
          }}
          >Submit</Button>
        </Card>
      </div>












   
      <div className="modal1">
      {showModal && (
        <div className="modal">
         
          <Card style={{ width: "44vh", height: "60vh", backgroundColor: "#BD8E38", borderRadius: "10px"}}>
            <CloseIcon onClick={() => setShowModal(false)} style={{marginLeft:"90%",marginTop:"3%"}}/>
          <Typography style={{ fontSize: "28px", color: '#0A253A', marginTop: "6%",textAlign:"start",marginLeft:"14%"}}>
            BOOK YOUR<br />
            APPOINTMENT
          </Typography>
        
          <Typography style={{ fontSize: "18px", color: 'white', marginTop: "4%", marginLeft: "-58%",fontFamily:" Poppins" }}>
            Name
          </Typography>
      
          <FormControl   sx={{
            '& > :not(style)': { width: '223px', height: '33px',marginLeft:"15%" },
          }}>

            <OutlinedInput
            id="input"
              // placeholder=" Enter Your Name"
              label="entrr"
              type="text"
              onChange={(e) => setName(e.target.value)}


            />
          </FormControl>
          <Typography style={{ fontSize: "18px", color: 'white', marginLeft: "17%", marginTop: "4%", marginLeft: "-58%",fontFamily:" Poppins"  }}>
            Email
          </Typography>
          <FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px',marginLeft:"15%" },
          }}>
            <OutlinedInput
            id="input"
              placeholder="  Enter Your mail Id"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Typography style={{ fontSize: "18px", color: 'white', marginLeft: "17%", marginTop: "4%", marginLeft: "-46%",fontFamily:" Poppins" }}>
            Phone No
          </Typography>
          <FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px',marginLeft:"15%" },
          }}>
            <OutlinedInput
            id="input"
              placeholder="  Enter Your Phone no"
              type="number"
              onChange={(e) => setNumber(e.target.value)}
            />
          </FormControl>

          <Button 
          onClick={() => {
            // setModal(false);
            if (
              number !== "" &&
              email !== "" &&
              name !== "" &&
              email.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              ) &&
              number.match(/^\d{10}$/)
            ) {
              handleSubmit(name,email,number);
              setSubmit(true);
              setName(" ");
              setEmail(" ");
              setNumber(" ");
              // setShowModal(false);
            }
            // modal3 === true ? setModal3(false) : setModal3(true);
          }}
          // onClick={() => setShowModal(false)}
          style={{ width: '223px', height: '33px', marginTop: "6%", marginLeft: "1%",
           backgroundColor: "#0A253A", fontSize: "16px" ,fontFamily:" Poppins",
           marginLeft:"10%"}} variant="contained">Submit</Button>
        </Card>
        </div>
      )}
    </div>







{/* this code for responsive mobile device */}

    <div  style={{marginTop: "-10%", height: "70vh"}} className="bookmodel">
        <Card style={{ width: "100%", backgroundColor: "#BD8E38",}} className="book-house">
    
          <center><Typography style={{ fontSize: "28px", color: '#0A253A',textAlign: "center", marginTop: "2%"}}>
            BOOK YOUR APPOINTMENT
          </Typography>
          </center>
          <center>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "3%", marginBottom: "10%"}}>
          <div style={{display: "flex", flexDirection: "column", marginLeft: "20px", marginRight: "20px"}}>
          <Typography style={{ fontSize: "18px", color: 'white',fontFamily:" Poppins" }}>
            Name
          </Typography>
      
          <center><FormControl   sx={{
            '& > :not(style)': { width: '223px', height: '33px', marginTop: "3%", marginBottom: "3%" },
          }}>

            <OutlinedInput
            id="input"
              placeholder=" Enter Your Name"
              type="text"
              value={name}
              style={{fontSize:16}}

              onChange={(e) => setName(e.target.value)}

            />
          </FormControl></center>

          </div>

          <div style={{display: "flex", flexDirection: "column", marginLeft: "20px", marginRight: "20px"}}>
          <Typography style={{ fontSize: "18px", color: 'white',fontFamily:" Poppins"  }}>
            Email
          </Typography>
          <center><FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px', marginTop: "3%", marginBottom: "3%" },
          }}>
            <OutlinedInput
            id="input"
            value={email}
              placeholder="  Enter Your mail Id"
              type="text"
              style={{fontSize:16}}

              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl></center>

          </div>

          <div style={{display: "flex", flexDirection: "column", marginLeft: "20px", marginRight: "20px"}}>
          <Typography style={{ fontSize: "18px", color: 'white',fontFamily:" Poppins" }}>
            Phone No
          </Typography>
          <center><FormControl sx={{
            '& > :not(style)': { width: '223px', height: '33px', marginTop: "3%", marginBottom: "3%" },
          }}>
            <OutlinedInput
               id="input"
               style={{fontSize:16}}
              placeholder="  Enter Your Phone no"
              type="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              
            />
          </FormControl></center>

          </div>


          <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: "2%"}}>
          <Button style={{ width: '223px', height: '33px',backgroundColor: "#0A253A", fontSize: "16px" ,fontFamily:" Poppins"}} variant="contained"
          onClick={() => {
            // setModal(false);
            if (
              number !== "" &&
              email !== "" &&
              name !== "" &&
              email.match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
              ) &&
              number.match(/^\d{10}$/)
            ) {
              handleSubmit(name,email,number);
              setSubmit(true);
              setName(" ");
              setEmail(" ");
              setNumber(" ");
            }
            // modal3 === true ? setModal3(false) : setModal3(true);
          }}>Submit</Button>

          </div>

</div>
</center>
        </Card>
        <Modal isOpen={submit} style={customStyles2} contentLabel="My dialog">
            <i
              class="fa-solid fa-xmark"
              style={{
                fontSize: "20px",
                paddingLeft: " 53rem",
                paddingTop: "1rem",
              }}
              onClick={() => {
                // setModal(false);
                setSubmit(false);
              }}
            ></i>

            <div>
              <Tick size={130} />
            </div>

            <h3 className="black-text" style={{ textAlign: "center" }}>
              Submitted Sucessfully
            </h3>
            {/* <h3 className="black-text" style={{ textAlign: "center" }}>

          We will Contact you soon..
        </h3> */}
          </Modal>
      </div>
   



    </Grid>



  );
};

export default Banner;
