import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imggg1 from "../image/imggg1.png";
import imggg2 from "../image/imggg2.png";
import imgg3 from "../image/imgg3.png";
import imgg4 from "../image/imgg4.png";
import imgg5 from "../image/imgg5.png";
import imgg6 from "../image/imgg6.png";
import imgg7 from "../image/imgg7.png";
import imgg8 from "../image/imgg8.png";
import imgg9 from "../image/imgg9.png";
import imgg10 from "../image/imgg10.png";
import imgg11 from "../image/imgg11.png";
import imgg12 from "../image/imgg12.png";
import imgg13 from "../image/imgg13.png";
import imgg14 from "../image/imgg14.png";
import imgg15 from "../image/imgg15.png";
import imgg16 from "../image/imgg16.png";
import imgg17 from "../image/imgg17.png";
import imgg18 from "../image/imgg18.png";
import imgg19 from "../image/imgg19.png";
import imgg20 from "../image/imgg20.png";
import { Box } from "@mui/material";

// import imgg1 from "../pages/imgg/imgg1.png";
// import "../Landing/amenities.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1050 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktops: {
    breakpoint: { max: 1050, min: 1024 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 650 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 650, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default class AmenitiesCarousel extends Component {
  render() {
    return (
      <>
        <div className="desk" style={{ marginLeft: "8%" }}>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <img src={imggg1} alt="imgg1" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px"
                  
                }}
              >
                1.LANDSCAPPING GARDEN
              </p>
            </div>
            <div>
              <img src={imggg2} alt="imgg2" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                2.KIDS PLAY AREA
              </p>
            </div>
            <div>
              <img src={imgg3} alt="imgg3" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                3.ROCK CLIMBING FOR KIDS
              </p>
            </div>

            <div>
              <img src={imgg4} alt="imgg4" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                width:"74%",
                padding:"6px"
                }}
              >
                4.PAVERED WALKING TRACK
              </p>
            </div>
            <div>
              <img src={imgg5} alt="imgg5" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                5.THE GRAND LOBBY ARCHID ELITE
              </p>
            </div>
            <div>
              <img src={imgg6} alt="imgg6" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                6.THE DREAMY MOOSE COFFEE SHOP
              </p>
            </div>

            <div>
              <img src={imgg7} alt="imgg7" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                7.PARTY CUM MEETING ROOM
              </p>
            </div>
            <div>
              <img src={imgg8} alt="imgg8" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                8.THE CASTLE COMMUNITY HALL
              </p>
            </div>
            <div>
              <img src={imgg9} alt="imgg9" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                9.THE SWEAT ARENA MOST ADVANCED GYMNASIUM
              </p>
            </div>
            <div>
              <img src={imgg10} alt="imgg10" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                10.THE MOOD WINGS- ROOF TOP POOL SIDE
              </p>
            </div>
            <div>
              <img src={imgg11} alt="imgg11" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                11.LIVE IN THE AEIR-THE ROOF TOP LOUNGE
              </p>
            </div>
            <div>
              <img src={imgg12} alt="imgg12" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                12.CALMING BUDDHA STATUE WITH FOUNTAIN
              </p>
            </div>
            <div>
              <img src={imgg13} alt="imgg13" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                13.OPEN ZUMBA, AEROBICS, YOGA PLACE
              </p>
            </div>
            <div>
              <img src={imgg14} alt="imgg14" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                14.HIT THE FIRE SITTING AREA
              </p>
            </div>
            <div>
              <img src={imgg15} alt="imgg15" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                15.POOL/BILLIARDS TABLE
              </p>
            </div>
            <div>
              <img src={imgg16} alt="imgg16" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                16.TABLE TENNIS
              </p>
            </div>
            <div>
              <img src={imgg17} alt="imgg17" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                  transform: "Translate(0%,-150%)",
                  fontSize: "10px",
                  width: "70%",
                  padding:"6px",
                }}
                
              >
                17.CARD ROOM
              </p>
            </div>
            <div>
              <img src={imgg18} alt="imgg18" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                18.EV CHARGING STATIONS
              </p>
            </div>
            <div>
              <img src={imgg19} alt="imgg19" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                19.VALET PARKING
              </p>
            </div>
            <div>
              <img src={imgg20} alt="imgg20" />
              <p
                style={{
                  backgroundColor: "rgba(10, 37, 58, 0.5)",
                 transform: "Translate(0%,-150%)",
                  fontSize: "18px",
                  width: "74%",
                  padding:"6px",
                }}
                
              >
                20. REFLEXOLOGY PATH
              </p>
            </div>
          </Carousel>
        </div>


        <div className="mobile" style={{}}>
          <center>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div>
                <img
                  src={imggg1}
                  alt="imgg1"
                  style={{ width: "250px" }}
                  className="image-l"
                />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  1.LANDSCAPPING GARDEN
                </p>
              </div>
              <div>
                <img src={imggg2} style={{ width: "250px" }} alt="imgg2" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  2.KIDS PLAY AREA
                </p>
              </div>
              <div>
                <img src={imgg3} style={{ width: "250px" }} alt="imgg3" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  3.ROCK CLIMBING FOR KIDS
                </p>
              </div>

              <div>
                <img src={imgg4} style={{ width: "250px" }} alt="imgg4" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                    width:"57%",
                    padding:"6px"
                  }}
                >
                  4.PAVERED WALKING TRACK
                </p>
              </div>
              <div>
                <img src={imgg5} style={{ width: "250px" }} alt="imgg5" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  5.THE GRAND LOBBY ARCHID ELITE
                </p>
              </div>
              <div>
                <img src={imgg6} style={{ width: "250px" }} alt="imgg6" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  6.THE DREAMY MOOSE COFFEE SHOP
                </p>
              </div>

              <div>
                <img src={imgg7} style={{ width: "250px" }} alt="imgg7" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  7.PARTY CUM MEETING ROOM
                </p>
              </div>
              <div>
                <img src={imgg8} style={{ width: "250px" }} alt="imgg8" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  8.THE CASTLE COMMUNITY HALL
                </p>
              </div>
              <div>
                <img src={imgg9} style={{ width: "250px" }} alt="imgg9" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  9.THE SWEAT ARENA MOST ADVANCED GYMNASIUM
                </p>
              </div>
              <div>
                <img src={imgg10} style={{ width: "250px" }} alt="imgg10" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  10.THE MOOD WINGS- ROOF TOP POOL SIDE
                </p>
              </div>
              <div>
                <img src={imgg11} style={{ width: "250px" }} alt="imgg11" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  11.LIVE IN THE AEIR-THE ROOF TOP LOUNGE
                </p>
              </div>
              <div>
                <img src={imgg12} style={{ width: "250px" }} alt="imgg12" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  12.CALMING BUDDHA STATUE WITH FOUNTAIN
                </p>
              </div>
              <div>
                <img src={imgg13} style={{ width: "250px" }} alt="imgg13" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  13.OPEN ZUMBA, AEROBICS, YOGA PLACE
                </p>
              </div>
              <div>
                <img src={imgg14} style={{ width: "250px" }} alt="imgg14" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  14.HIT THE FIRE SITTING AREA
                </p>
              </div>
              <div>
                <img src={imgg15} style={{ width: "250px" }} alt="imgg15" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  15.POOL/BILLIARDS TABLE
                </p>
              </div>
              <div>
                <img src={imgg16} style={{ width: "250px" }} alt="imgg16" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  16.TABLE TENNIS
                </p>
              </div>
              <div>
                <img src={imgg17} style={{ width: "250px" }} alt="imgg17" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  17.CARD ROOM
                </p>
              </div>
              <div>
                <img src={imgg18} style={{ width: "250px" }} alt="imgg18" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  18.EV CHARGING STATIONS
                </p>
              </div>
              <div>
                <img src={imgg19} style={{ width: "250px" }} alt="imgg19" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  19.VALET PARKING
                </p>
              </div>
              <div>
                <img src={imgg20} style={{ width: "250px" }} alt="imgg20" />
                <p
                  style={{
                    backgroundColor: "rgba(10, 37, 58, 0.5)",
                    transform: "Translate(0%,-150%)",
                    fontSize: "14px",
                     width:"57%",
                     padding:"6px"
                  }}
                  
                >
                  20. REFLEXOLOGY PATH
                </p>
              </div>
            </Carousel>
          </center>
        </div>
      </>
    );
  }
}
