import React, { Component } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import img1 from "../imgg2/img1.png"
import img2 from "../imgg2/img2.png"
import img3 from "../imgg2/img3.png"
import img4 from "../imgg2/img4.png"
import img5 from "../imgg2/img5.png"
import img6 from "../imgg2/img6.png"
import img7 from "../imgg2/img7.png"
import img8 from "../imgg2/img8.png"
import img9 from "../imgg2/img9.png"


// import imgg1 from "../pages/imgg/imgg1.png";
// import "../Landing/amenities.css";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    desktops: {
      breakpoint: { max: 1050, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 650, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

export default class AmenitiesCarousel extends Component {
  render() {
    return (
      <>
      <div  className="desk" style={{marginLeft:"8%"}}>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            >
            <div>
                <img src={img1} alt='imgg1'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>1.SECURITY ROOM</p>
            </div>
            <div>
                <img src={img2} alt='imgg2'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}> 2.24/7 SECURITY</p>
            </div>
            <div>
                <img src={img3} alt='imgg3'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>3.24/7 CCTV SERVIELIANCE</p>
            </div>

            <div>
                <img src={img4} alt='imgg4'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>4.24/7 POWE BACKUP</p>
            </div>
            <div>
                <img src={img5} alt='imgg5'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>5.24/7 WATER SUPPLY</p>                
            </div>
            <div>
                <img src={img6} alt='imgg6'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>6.BASEMENT PARKING</p>
            </div>


            <div>
                <img src={img7} alt='imgg7'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>7.LIFT WITH ARD</p>
            </div>
            <div>
                <img src={img8} alt='imgg8'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>8.SLIT PARKING</p>
            </div>
            <div>
                <img src={img9} alt='imgg9'/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(1%,-150%)", fontSize: "18px", width: "73%"}}>9.VIDEO DOOOR PHONE</p>
            </div>
            
        </Carousel>
       
       
      </div>



      <div  className="mobile" style={{marginLeft:"16%"}}>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            >
            <div>
                <img src={img1} alt='imgg1' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>1.SECURITY ROOM</p>
            </div>
            <div>
                <img src={img2} alt='imgg2' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}> 2.24/7 SECURITY</p>
            </div>
            <div>
                <img src={img3} alt='imgg3' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>3.24/7 CCTV SERVIELIANCE</p>
            </div>

            <div>
                <img src={img4} alt='imgg4' style={{ width: "250px" }} />
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)", transform: "Translate(0%,-150%)", fontSize: "20px", width: "150px"}}>4.24/7 POWE BACKUP</p>
            </div>
            <div>
                <img src={img5} alt='imgg5' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>5.24/7 WATER SUPPLY</p>                
            </div>
            <div>
                <img src={img6} alt='imgg6' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>6.BASEMENT PARKING</p>
            </div>


            <div>
                <img src={img7} alt='imgg7' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>7.LIFT WITH ARD</p>
            </div>
            <div>
                <img src={img8} alt='imgg8' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>8.SLIT PARKING</p>
            </div>
            <div>
                <img src={img9} alt='imgg9' style={{ width: "250px" }}/>
                <p style={{backgroundColor: "rgba(10, 37, 58, 0.5)",padding:"6px", transform: "Translate(0%,-150%)", fontSize: "14px", width: "66%"}}>9.VIDEO DOOOR PHONE</p>
            </div>
            
        </Carousel>
       
       
      </div>

      </>
    )
  }
}