import Banner from "./Banner";
import AboutUs from "./AboutUs";
import LatestLaunches from "./latestLaunches";
import "./index.css"
import Main from '../../components/Main/Main';
import SearchFilter from "./Banner/minicomponent/SearchFilter/index";
import { useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import React, { useState } from "react";
import { useTheme } from '@mui/material/styles';
import Locate from '../../images/bgmlocate.svg'
import Videoback from '../../images/videoback.png'
import Videoa from '../../images/locationimg.png'
import "../../components/Main/Main.css"
import Connect1 from '../../images/Frame 6695.svg';
import Connect2 from '../../images/Connectivity-5.png';
import Connect3 from '../../images/connect3.svg'
import Connect4 from '../../images/Address.svg'
import Connect5 from '../../images/Runway.png'
import { Typography, Button, } from "@mui/material";
import Box from '@mui/material/Box';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
import "../../components/overview.css";
import Banner2 from "../../images/upcoming/Rectangle 166.png"
import 'react-multi-carousel/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Gallary from '../../../src/images/buildingbg.png'
import Gallary2 from '../../../src/images/imageslide.png'
import { Card } from '@mui/material';
import map from '../../images/completed/map.png'
import imgg1 from "../../pages/image/imgg1.png"
import imgg2 from "../../pages/image/imgg2.png"
import imgg3 from "../../pages/image/imgg3.png"
import imgg4 from "../../pages/image/imgg4.png"
import imgg5 from "../../pages/image/imgg5.png"
import imgg6 from "../../pages/image/imgg6.png"
import imgg7 from "../../pages/image/imgg7.png"
import imgg8 from "../../pages/image/imgg8.png"
import imgg9 from "../../pages/image/imgg9.png";
import imgg10 from "../../pages/image/imgg10.png";
import imgg11 from "../../pages/image/imgg11.png";
import imgg12 from "../../pages/image/imgg12.png";
import imgg13 from "../../pages/image/imgg13.png";
import imgg14 from "../../pages/image/imgg14.png";
import imgg15 from "../../pages/image/imgg15.png";
import imgg16 from "../../pages/image/imgg16.png";
import imgg17 from "../../pages/image/imgg17.png";
import imgg18 from "../../pages/image/imgg18.png";
import imgg19 from "../../pages/image/imgg19.png";
import imgg20 from "../../pages/image/imgg20.png";
import CallIcon from '@mui/icons-material/Call';
import instagram from "../../components/footer/images/instagram.svg";
import facebook from "../../components/footer/images/facebook.svg";
import twitter from "../../components/footer/images/twitter.svg";
import img1 from "../../pages/imgg2/img1.png"
import img2 from "../../pages/imgg2/img2.png"
import img3 from "../../pages/imgg2/img3.png"
import img4 from "../../pages/imgg2/img4.png"
import img5 from "../../pages/imgg2/img5.png"
import img6 from "../../pages/imgg2/img6.png"
import img7 from "../../pages/imgg2/img7.png"
import img8 from "../../pages/imgg2/img8.png"
import img9 from "../../pages/imgg2/img9.png"
import Siteplan from '../../images/siteplan.svg'
import Slider from 'react-slick';
import Floor1 from '../../images/floor1.png'
import Floor2 from '../../images/floor2.png'
import Floor3 from '../../images/floor3.png'
import aa from "../../pages/imgg2/aa.png";
import bb from "../../pages/imgg2/bb.png";
import cc from "../../pages/imgg2/cc.png";
import dd from "../../pages/imgg2/dd.png";
import ee from "../../pages/imgg2/ee.png";
import ff from "../../pages/imgg2/ff.png";
import gg from "../../pages/imgg2/gg.png";
import h from "../../pages/imgg2/h.png";
import i from "../../pages/imgg2/i.png";
import j from "../../pages/imgg2/j.png"
import k from "../../pages/imgg2/k.png";
import l from "../../pages/imgg2/l.png";
import hospital from "../../images/team/hospital.png";
import school from "../../images/team/school.png";
import petrol from "../../images/team/petrol.png";
import uni from "../../images/team/uni.png";
import office from "../../images/team/office.png";
import hotel from "../../images/team/hotel.png";
import rest from "../../images/team/rest.png";
import shopify from "../../images/team/shopify.png";
import EmailIcon from '@mui/icons-material/Email';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../images/Company_Logo/logo.svg";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import ArchidSqLogo from "../../images/Company_Logo/ArchidSqLogo.PNG";
import Alogo from "../../images/Company_Logo/logoooo.png";
import ArchidLogo from "../../images/Company_Logo/ArchidLogo.png";
import DateTimePicker from "react-datetime-picker";
import Image1 from "../../images/Company_Logo/png ar.png";
import "react-phone-number-input/style.css";
import CloseIcon from '@mui/icons-material/Close';
import { Container } from "react-bootstrap";
import { resetWarningCache, Tick } from "react-crude-animated-tick";
import Unitplan3 from '../../images/unitplan3.jpg'
import Unitplan4 from '../../images/unitplan4.jpg'
import Unitplan5 from '../../images/unitplan5.jpg'
import Carousel from 'react-bootstrap/Carousel';
import Amenities from "../Landing/indexamenities.js";
import Amenities2 from "../Landing/indexamenities2.js";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Socialmedia from "../Landing/indexsocialmedia";
import ProjectOverviw from "../../images/ProjectOverview-3.svg";
import ABOUTUS from "../../pages/Landing/AboutUs";

const LandingScreen = () => {
  const [showImage1, setShowImage1] = React.useState('siteplan');

  const assignValue = () => {
    setShowImage1('siteplan');

  };
  const assignValue1 = () => {
    setShowImage1('1-8thfloor');
    setModal(true)
  };
  const assignValue2 = () => {
    setShowImage1('10thfloor');
    setModal(true)
  };
  const assignValue3 = () => {
    setShowImage1('roofplan');
    setModal(true)
  };
  const settings2 = {

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    showArrows: false
  };
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2
        }
      }
    ]
  };
  async function handeldownload(number, email) {
    // e.preventDefault();
    try {
      let res = await fetch(
        " https://archid-backend-tekq2uwgbq-el.a.run.app/add_brochure",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "Application/json",
          },
          body: JSON.stringify({
            number: number,
            email: email,
            // requirement: requirement,
          }),
        }
      );
      let resJson = await res.json();
      console.log(resJson)
      if (resJson.status) {
        // setNumber("");
        // setEmail("");
        // setRequirement("");
      } else {
        // setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };
  async function handleSubmits(name, email, number) {
    // e.preventDefault();
    try {
      let res = await fetch(
        "https://archid-backend-tekq2uwgbq-el.a.run.app/book_appoint",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "Application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            phoneNo: number,
          }),
        }
      );
      let resJson = await res.json();
      console.log(resJson)
      if (resJson.status) {
        // setNumber("");
        // setEmail("");
        // setRequirement("");
      } else {
        // setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [showImage2, setShowImage2] = React.useState('luxry');
  const [modal2, setModal2] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [Date_Time, onChange] = useState(new Date());

  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [requirement, setRequirement] = useState("");
  const [name, setName] = useState("");
  const [type, setProperty] = useState("");
  const [messages, setMessages] = useState("");

  const [modal3, setModal3] = useState(false);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);

  const assignValue4 = () => {
    setShowImage2('luxry');
  };
  const assignValue5 = () => {
    setShowImage2('others');
  };
  console.log(showImage2)
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px",
      borderRadius: "0px",
      height: "400px",
      zIndex: "200",
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "628px",
      height: "364px",
      zIndex: "200",
      borderRadius: "20px",
    },
  };
  const customStyles3 = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "390px",
      height: "420px",
      zIndex: "200",
      borderRadius: "20px",
    },
  };
  const customStyles2 = {
    content: {
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "15px",
      height: "300px",
      zIndex: "200",
    },
  };

  async function handleSubmit(name, number, email, messages) {
    // e.preventDefault();
    try {
      let res = await fetch(
        "https://archid-backend-tekq2uwgbq-el.a.run.app/contact_component",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "Application/json",
          },
          body: JSON.stringify({
            name: name,
            contactNo: number,
            emailId: email,
            message: messages,
          }),
        }
      );
      let resJson = await res.json();
      console.log(resJson)
      if (resJson.status) {
        // setNumber("");
        // setEmail("");
        // setRequirement("");
      } else {
        // setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // let handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     let res = await fetch(
  //       "http://192.168.0.102:8080/contact_component",
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-type": "Application/json",
  //         },
  //         body: JSON.stringify({
  //           Name:   name,
  //           ContactNo: number,
  //           EmailId: email,
  //           Message: messages,
  //         }),
  //       }
  //     );
  //     let resJson = await res.json();
  //     if (resJson.status) {
  //       // setNumber("");
  //       // setEmail("");
  //       // setRequirement("");
  //     } else {
  //       setMessage("Some error occured");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <div style={{ backgroundColor: "#fff", overflowX: "hidden" }}>

      <div>
        <div className="archid-navbar">
          {/* <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="nav-bar">
            <Navbar.Brand>
              <a target="_blanck" href="https://archidhomes.in/">

                <img src={Alogo} className="mainlogo" style={{ height: 85 }} alt="Loading..." />

              </a>
            </Navbar.Brand>
            <Navbar.Brand>
              <HashLink
                smooth

                className="logoimg"
                to="/#top"
                style={{ paddingLeft: 27, paddingbottom: 0, borderWidth: 0 }}
              >
                <img className="logoimg" src={Logo} style={{ height: 80, width: 150 }} alt="Loading..." />
              </HashLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
            <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
            <Navbar.Collapse id="responsive-navbar-nav"></Navbar.Collapse>
           
          </Navbar> */}
          <center>

            <img src={Alogo} className="mainlogo" style={{ height: 135 }} alt="Loading..." />
          </center>


          <Modal isOpen={modal2} style={customStyles3} contentLabel="Example Modal">
            <form className="" >
              <div className="d-flex modal_head">
                <h3
                  className="black-text"
                  style={{
                    fontSize: "20px",
                    marginBottom: "30px",
                    marginTop: "20px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {" "}
                  Fill up The From
                </h3>

                <i
                  class="fa-solid fa-xmark"
                  style={{ fontSize: "20px" }}
                  onClick={() => setModal2(false)}
                ></i>
              </div>

              <label
                for="basic-url"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Contact Number *
              </label>

              <div class="input-group mb-3">
                <input
                  type="number"
                  required
                  class="form-control"
                  id="basic-url"
                  // placeholder="number"
                  style={{ width: 400 }}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  maxLength="10"
                  minLength="10"
                  pattern="[1-9]{1}[0-9]{9}"
                />
              </div>

              <label
                for="basic-url"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                EMAIL ADDRESS *
              </label>

              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="basic-url"
                  style={{ borderColor: "black" }}
                  aria-describedby="basic-addon3"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* <label for="basic-url" style={{ fontSize: "16px", marginBottom: "10px" }}>Requirement *</label>

          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              style={{ height: "80px", borderColor: "black" }}
              required
              value={requirement}
              // placeholder="Requirement"
              onChange={(e) => setRequirement(e.target.value)}
            />
          </div> */}
              <button
                className="btn btn-primary align-items-center justify-content-center register_button"
                // type="submit"
                style={{
                  marginLeft: "17%",
                  borderRadius: "0px",
                  fontSize: "18px",
                  marginTop: "16px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                // disabled={{onclick}}
                onClick={() => {
                  // setModal2(false);
                  // setModal3(true)
                  if (
                    number !== "" &&
                    email !== "" &&
                    email.match(
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    ) &&
                    number.match(/^\d{10}$/)
                  ) {
                    handeldownload(number, email)
                    setModal3(true);
                    setModal2(false);
                    setNumber("");
                    setEmail("");
                    // window.open(data.ameneties.brochure, '_blank')
                  }
                }}
              >
                SUBMIT
              </button>
            </form>
          </Modal>
          <Modal isOpen={modal} style={customStyles3} contentLabel="Example Modal">
            <form className="" >
              <div className="d-flex modal_head">
                <h3
                  className="black-text"
                  style={{
                    fontSize: "20px",
                    marginBottom: "30px",
                    marginTop: "20px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {" "}
                  Fill up The From
                </h3>

                <i
                  class="fa-solid fa-xmark"
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setModal(false);
                    assignValue()
                  }}
                ></i>
              </div>

              <label
                for="basic-url"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Name *
              </label>

              <div class="input-group mb-3">
                <input
                  type="number"
                  required
                  class="form-control"
                  id="basic-url"
                  // placeholder="number"
                  style={{ width: 400 }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                // maxLength="10"
                // minLength="10"
                // pattern="[1-9]{1}[0-9]{9}"
                />
              </div>
              <label
                for="basic-url"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Contact Number *
              </label>

              <div class="input-group mb-3">
                <input
                  type="number"
                  required
                  class="form-control"
                  id="basic-url"
                  // placeholder="number"
                  style={{ width: 400 }}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  maxLength="10"
                  minLength="10"
                  pattern="[1-9]{1}[0-9]{9}"
                />
              </div>

              <label
                for="basic-url"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                EMAIL ADDRESS *
              </label>

              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="basic-url"
                  style={{ borderColor: "black" }}
                  aria-describedby="basic-addon3"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* <label for="basic-url" style={{ fontSize: "16px", marginBottom: "10px" }}>Requirement *</label>

          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              style={{ height: "80px", borderColor: "black" }}
              required
              value={requirement}
              // placeholder="Requirement"
              onChange={(e) => setRequirement(e.target.value)}
            />
          </div> */}
              <button
                className="btn btn-primary align-items-center justify-content-center register_button"
                // type="submit"
                style={{
                  marginLeft: "17%",
                  borderRadius: "0px",
                  fontSize: "18px",
                  marginTop: "16px",
                  paddingLeft: "70px",
                  paddingRight: "70px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                onClick={() => {
                  if (

                    name !== "" &&
                    number !== "" &&
                    email !== "" &&
                    email.match(
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    ) &&
                    number.match(/^\d{10}$/)
                  ) {
                    handleSubmits(name, number, email, "")
                    setModal4(true);
                    setModal(false);
                    setNumber("");
                    setName("");
                    setEmail("");
                  }
                }}
              >
                SUBMIT
              </button>
            </form>
          </Modal>

          <Modal isOpen={modal3} style={customStyles2} contentLabel="My dialog">
            <i
              class="fa-solid fa-xmark"
              style={{
                fontSize: "20px",
                paddingLeft: " 53rem",
                paddingTop: "1rem",
              }}
              onClick={() => {
                setModal3(false);
                setModal2(false);
              }}
            ></i>

            <div>
              <Tick size={130} />
            </div>

            <h3 className="black-text" style={{ textAlign: "center" }}>
              Submitted Sucessfully
            </h3>
            {/* <h3 className="black-text" style={{ textAlign: "center" }}>

          We will Contact you soon..
        </h3> */}

            <button
              className="btn btn-primary align-items-center justify-content-center register_button"
              type="submit"
              style={{
                marginLeft: "20%",
                borderRadius: "0px",
                fontSize: "18px",
                marginTop: "16px",
                paddingLeft: "70px",
                paddingRight: "70px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
              onClick={() => {
                // setModal2(false);
                setModal3(false)
                // modal2 === false ? setModal2(false) : setModal3(true);
                window.open("https://firebasestorage.googleapis.com/v0/b/archid-builders.appspot.com/o/eliet%2FArchid%20Elite%20%20Brochure.pdf?alt=media&token=8a5b3505-fc57-4349-85ab-ac83a32faa4f", "_blank")

              }}
            >
              DOWNLOAD BROCHURE
            </button>

          </Modal>
          <Modal isOpen={modal4} style={customStyles2} contentLabel="My dialog">
            <i
              class="fa-solid fa-xmark"
              style={{
                fontSize: "20px",
                paddingLeft: " 53rem",
                paddingTop: "1rem",
              }}
              onClick={() => {
                // setModal(false);
                setModal4(false);
              }}
            ></i>

            <div>
              <Tick size={130} />
            </div>

            <h3 className="black-text" style={{ textAlign: "center" }}>
              Submitted Sucessfully
            </h3>
            {/* <h3 className="black-text" style={{ textAlign: "center" }}>

          We will Contact you soon..
        </h3> */}
          </Modal>


          <Container open={open} setOpen={setOpen} />
        </div>
      </div>
      <div>

        <Banner />
      </div>


      <div id="header" className="about-us-container">

        <center>

          <Nav className="mr-auto navtext">
            <Nav.Link
              href="#home"
              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#header"  className="navt" style={{ color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Overview
              </HashLink>
            </Nav.Link>

            <Nav.Link
              href="#home"
              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#CONNECTIVITY"  className="navt" style={{ color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Connectivity
              </HashLink>
            </Nav.Link>

            <Nav.Link

              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#LOCATION"  className="navt" style={{ color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Location
              </HashLink>
            </Nav.Link>
            <Nav.Link
              href="#home"
              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#LAYOUT"  className="navt" style={{ color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Layout
              </HashLink>
            </Nav.Link>

            <Nav.Link
              href="#home"
              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#Gallery"  className="navt" style={{ color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Gallery
              </HashLink>
            </Nav.Link>
            <Nav.Link
              href="#home"
              className="n-link"
              style={{ color: "#000", fontWeight: 600 }}
            >
              <HashLink smooth to="#AMENITIES"  className="navt" style={{ padding: 10, color: " #ECD571", fontWeight: 400, fontSize: "18px", lineHeight: '27px', fontFamily: 'Poppins' }}>
                Amenities
              </HashLink>
            </Nav.Link>




          </Nav>
        </center>

        <center><div style={{ width: "100%" }} >
          <h1 className="about-us-heading hee" style={{ fontSize: "40px" }}>PROJECT OVERVIEW </h1>
        </div>
        </center>

        <center>
          <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", paddingBottom: "2%", marginLeft: "-2%", marginTop: "2%" }}>
            <ul className="about-us-container-ul">
              <li> Location</li>
              <li>Luxury </li>
              <li>Living</li>
            </ul>
          </div>
        </center>


        <center>
          <div className="pro-over">
            <img src={ProjectOverviw} className="img-si" />
          </div>
        </center>



        {/* <center>
        <div className="project-main-container">

        <div className="first-boxs">
          <div className="box1">
              <span className="head-line">POSSESSION</span>
              <span className="sub-head-line">December 2026</span>
          </div>
          
          <div className="wid-col"></div>

          <div className="box1">
              <span className="head-line">PROPERTY  TYPE</span>
              <span className="sub-head-line">Residential Apartment</span>
          </div>
          
          <div className="wid-col"></div>
          
          <div className=" box3 box2">
              <span className="head-line">UNITS</span>
              <span className="sub-head-line">3.5 & 4.5 BHK Apartment</span>
          </div>
          </div>
          <div className="wid-row"></div>

          <div className="second-boxs">
          <div className="box3 two">
              <span className="head-line">LOCATION</span>
              <span className="sub-head-line">Patia, Bhubaneswar</span>
          </div>

          <div className="wid-col-2"></div>
          <div className="box3 two" style={{marginLeft:"2.4%"}}>
              <span className="head-line price" >PRICE</span>
              <span className="sub-head-line p-sub">1.98 Cr*(onwards)</span>
          </div>
          
          <div className="wid-col-2 wid-t"></div>

          <div className=" box3  box2" style={{marginRight:"2%"}}>
              <span className="head-line rera" style={{paddingLeft:"44px"}}>RERA NO</span>
              <span className="sub-head-line rp" style={{paddingLeft:"44px"}}>RP/19/2023/00853</span>
          </div>
          </div>
        </div>

        </center> */}
        <center>
          <Button
            // onClick={() => {
            //   window.open("https://firebasestorage.googleapis.com/v0/b/archid-builders.appspot.com/o/eliet%2FArchid%20Elite%20%20Brochure.pdf?alt=media&token=8a5b3505-fc57-4349-85ab-ac83a32faa4f", "_blank")
            // }} 
            onClick={() => {
              setModal2(true);
              // setModal1(false)
              // window.open(data.ameneties.brochure, '_blank')
            }}
            // target="_blank"
            className="dwonload-brochure" style={{ color: "#fff", marginBottom: "3%" }} > BROCHURE</Button> </center>




        <div className='desktop' style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", marginLeft: "3%", marginBottom: "50px", marginRight: "5%" }}>
          <div className="" >
            <h1 className="" style={{ fontSize: "30px", color: " #BF9654",fontFamily:"Zilla Slab",paddingBottom:"15px" }}>ABOUT US</h1>
            <div style={{ width: "100%" }} className="about-us-text">

              <p style={{ textAlign: "justify", fontWeight: "400", fontSize: "18px", fontFamily: "Lato", paddingBottom: "10px", lineHeight: "35px" }} className="elite-text">
                <b style={{ color: "#BF9654", }}>
                  ARCHID ELITE   &nbsp;
                </b>
                stands for it’s most iconic identity and luxurious living standard It gives the residents of the society. It’s 11 story standing floor & High rise tower see over the Smart city Bhubaneswar. The 3 side open spacious flats gives you a king sized ultra living experience so as the balcony view soothe your moment with your loved one’s. In one line it’s the next iconic tower of the city that gives pleasure to your mind and soul.
              </p>

            </div>


          </div>
          <img style={{ width: "40%", height: "330px", marginRight: "5%" }} className="about-us-image" src={Banner2} alt="...Loading"

          />



        </div>


        <div className='mob' style={{ marginBottom: "50px" }}>
          <div className="" >
            <h1 className="aboutt" style={{ fontSize: "30px", color: " #BF9654", fontFamily: "Zilla Slab", marginLeft: "3.3%" }}>ABOUT US</h1>
            <center>
              <div style={{ width: "92%" }} >

                <p style={{ textAlign: "justify", fontWeight: "400", fontSize: "18px", fontFamily: "Lato", paddingBottom: "10px", lineHeight: "25px" }} className="elite-text">
                  <b style={{ color: "#BF9654", }}>
                    ARCHID ELITE   &nbsp;
                  </b>
                  stands for it’s most iconic identity and luxurious living standard It gives the residents of the society. It’s 11 story standing floor & High rise tower see over the Smart city Bhubaneswar. The 3 side open spacious flats gives you a king sized ultra living experience so as the balcony view soothe your moment with your loved one’s. In one line it’s the next iconic tower of the city that gives pleasure to your mind and soul.
                </p>

              </div>
            </center>


          </div>
          <center>
            <img style={{ width: "100%" }} src={Banner2} alt="...Loading"

            />
          </center>



        </div>











        {/* <div clsssName='desktopview-about' >
          <div>
            <h1  style={{ fontSize: "30px",color:" #BF9654",fontFamily:"Zilla Slab"}}>ABOUT US</h1>
           
            <div style={{width:"100%"}} className="about-us-text">

              <p style={{ textAlign: "justify", fontWeight: "400", fontSize: "18px",fontFamily:"Lato",paddingBottom:"10px",lineHeight:"32px"}} className="elite-text"> 
              <b style={{ color: "#BF9654", }}>
                ARCHID ELITE   &nbsp;
                </b>  
                stands for it’s most iconic identity and luxurious living standard It gives the residents of the society. It’s 11 story standing floor & High rise tower see over the Smart city Bhubaneswar. The 3 side open spacious flats gives you a king sized ultra living experience so as the balcony view soothe your moment with your loved one’s. In one line it’s the next iconic tower of the city that gives pleasure to your mind and soul.
              </p>

            </div>
           
          </div>
        
          <img className="about-us-image" src={Banner2}  style={{width:"50%"}} alt="...Loading"/>

          
          
        </div> */}

        {/* <ABOUTUS /> */}






      </div>





      <div id="CONNECTIVITY" style={{ backgroundColor: "white" }} className="conectivity-margin">
        <center className="connectivity-header hee">
          CONNECTIVITY

        </center>
        <center className="connectivity-header-2">
          <p className="coooo" style={{ fontSize: "21px", fontWeight: "400", textAlign: "center", color: "black", marginBottom: "0.8%", marginTop: "2%", fontFamily: "poppins" }}>
            Located in Patia, Archid Elite is the address which stands for it’s uniqueness,Well connected location with
            range of everything what your daily life needs .That’s why we say We value your future ..
          </p>

        </center>
        <center>

          <img src={Connect1} className="connimg" />
          <img src={Connect2} className="connmobile" />
        </center>
        <div style={{ backgroundColor: "white" }} >

        </div>
      </div>






      <div id="LOCATION">
        <div style={{ backgroundColor: "white" }}  >
          <Box style={{ width: "100%", paddingTop: "5%" }}>

            <div style={{ position: "relative" }}>
              <img src={Locate} alt="Image" style={{ width: "100%", height: "100%" }} />
              <img src={Videoa} alt="Image" className="video-container" style={{ width: "80%", height: "100%", marginTop: "5%" }} />
              {/* <video className="" src={Videoa} muted autoPlay loop /> */}



              <center className="loc5" >
                LOCATION

              </center>

              {/* <Typography className="loc"

                style={{ color: "rgba(214, 180, 86, 1),rgba(236, 213, 113, 1),rgba(188, 141, 55, 1)", fontSize: "50px", fontFamily: "Zilla Slab", marginLeft: "40%", fontWeight: 500, position: "absolute", top: 5, left: 0, }}
              >LOCATION</Typography> */}
              <div >

              </div>


            </div>
          </Box>
        </div>
      </div>

      <div id="LAYOUT" style={{ marginTop: "10%" }}>

        <div style={{ backgroundColor: "rgba(191, 150, 84, 0.75)" }} className="the" >
          <h1 className="hee" style={{ paddingTop: "2%" }}>THE MAGICAL LAYOUT</h1>
          <div className="mob-txt">
            <p className="thee" style={{ fontSize: "20px", textAlign: "center", color: "black", marginTop: "2%", fontFamily: "poppins" }}>At Archid Elite , The 11 story floors signify the 11:11 magical numerology fact which attracts the divine feeling and prosperity. Archid
              Elite stands to amaze the people around by it’s architectural design, look & the residents living in here will experience an world
              class & unbeatable lifestyle .</p>
          </div>
          <center>
            {/* <div style={{ marginTop: "4%" }} > */}

            {/* <Tabs className="slidegallary2"> */}
            {/* <Tabs > */}

            <Button className={showImage1 === "siteplan" ? 'button selectedbtn' : 'button'} onClick={assignValue} style={{ marginLeft: 10, marginTop: "2%" }}>Site plan</Button>
            <Button className={showImage1 === "1-8thfloor" ? 'button selectedbtn' : 'button'} onClick={assignValue1} style={{ marginLeft: 10, marginTop: "2%" }} >3.5Bhk (Plan-1)
            </Button>
            <Button className={showImage1 === "10thfloor" ? 'button selectedbtn' : 'button'} onClick={assignValue2} style={{ marginLeft: 10, marginTop: "2%" }}>3.5Bhk (Plan-2)</Button>
            <Button className={showImage1 === "roofplan" ? 'button selectedbtn' : 'button'} onClick={assignValue3} style={{ marginLeft: 10, marginTop: "2%" }}>4.5 BHK</Button>



            {/* </Tabs> */}

            {/* </div> */}

            <Box className="themagical2">
              {showImage1 === 'siteplan' && <div><img src={Siteplan} style={{ width: "40%", marginTop: "2%" }} /></div>}
              {showImage1 === '1-8thfloor' && <div><img src={Unitplan3} style={{ width: "40%", marginTop: "2%" }} /></div>}
              {showImage1 === '10thfloor' && <div><img src={Unitplan4} style={{ width: "40%", marginTop: "2%" }} /></div>}
              {showImage1 === 'roofplan' && <div><img src={Unitplan5} style={{ width: "40%", marginTop: "2%" }} /></div>}
              {/* {showImage1 === 'luxry'&&<div> <img src={Floor4}  /></div>} */}
            </Box>
            <Box className="themagical3">
              {showImage1 === 'siteplan' && <div><img src={Siteplan} style={{ width: "80%", marginTop: "2%" }} /></div>}
              {showImage1 === '1-8thfloor' && <div><img src={Unitplan3} style={{ width: "80%", marginTop: "2%" }} /></div>}
              {showImage1 === '10thfloor' && <div><img src={Unitplan4} style={{ width: "80%", marginTop: "2%" }} /></div>}
              {showImage1 === 'roofplan' && <div><img src={Unitplan5} style={{ width: "80%", marginTop: "2%" }} /></div>}
              {/* {showImage1 === 'luxry'&&<div> <img src={Floor4}  /></div>} */}
            </Box>


          </center>

        </div>
      </div>

      <div clsssName="gal-sec" id="Gallery" >


        <div clsssName="gallary-contain">
          {/* <img clssName="gal" src={Gallary} alt="Image" style={{ width: "100%", height: "100%" }} /> */}
          <center className="gallary25 hee" >
            GALLERY

          </center>

          <div className="slidergallary">

            <Carousel slide={true} interval={1000} controls={true} indicators={true}>
              <Carousel.Item>
                <img className="d-block w-100" src={Gallary2} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={aa} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={bb} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={cc} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={dd} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={ee} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={ff} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={gg} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={h} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={i} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={j} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={k} />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={l} />
              </Carousel.Item>


            </Carousel>
          </div>


          {/* 
          <Slider {...settings} className="slidergallary">
            <div><img src={Gallary2} style={{ width: "100%" }} /></div>
            <div><img src={aa} style={{ width: "100%" }} /></div>
            <div><img src={bb} style={{ width: "100%" }} /></div>
            <div><img src={cc} style={{ width: "100%" }} /></div>
            <div><img src={dd} style={{ width: "100%" }} /></div>
            <div><img src={ee} style={{ width: "100%" }} /></div>
            <div><img src={ff} style={{ width: "100%" }} /></div>
            <div><img src={gg} style={{ width: "100%" }} /></div>
            <div><img src={h} style={{ width: "100%" }} /></div>
            <div><img src={i} style={{ width: "100%" }} /></div>
            <div><img src={j} style={{ width: "100%" }} /></div>
            <div><img src={k} style={{ width: "100%" }} /></div>
            <div><img src={l} style={{ width: "100%" }} /></div>



          </Slider> */}

        </div>

      </div>

      <div style={{ backgroundColor: "white" }} className="aminitieshead hee" id="AMENITIES">
        <center className="AMENITIES">
          AMENITIES

        </center>

        <div style={{ marginTop: "1%" }} className="w">
          <h5>Witness the Magical view of the Majestic Address</h5>
          <h6 style={{ marginTop: "1%" }}>  OVER 25+ WORLD CLASS LUXURY AMENITIES TO KEEP YOU ON TOE</h6>
        </div >
        <div style={{ marginTop: "4%" }} >

          <Tabs className="Luxury">
            <TabList style={{ marginLeft: "4px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Button className={showImage2 === "luxry" ? 'button selectedbtn' : 'button'} onClick={assignValue4} style={{}}>LUXURY</Button>
              <Button className={showImage2 === "others" ? 'button selectedbtn' : 'button'} onClick={assignValue5} style={{ marginLeft: "4px" }}>OTHERS</Button>
            </TabList>
          </Tabs>

        </div >
      </div>

      <div>
        {showImage2 === 'luxry' && (<div className="" style={{ marginTop: "5%" }}>

          <Amenities />

        </div >)}

        {showImage2 === 'others' && (

          <div>
            <Amenities2 />
          </div>

        )}
      </div>


      <div id="social">
        <div  >
          <Box style={{ width: "100%" }}>

            <div style={{ position: "relative" }}>
              {/* <img src={Locate} alt="Image" style={{ width: "100%", }} /> */}

              <Socialmedia />

              {/* <Carousel slide={true} interval={1000} controls={true} indicators={true} className="socialvedio">
                <Carousel.Item >
                  <center>

                    <iframe width="80%" height="515" className="vedioone" style={{ marginTop: "10%" }} src="https://www.youtube.com/embed/JPXo3Tg4Gow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </center>
                </Carousel.Item>
                <Carousel.Item >
                  <center>

                    <iframe width="80%" height="515" className="vediotow" style={{ marginTop: "10%" }} src="https://www.youtube.com/embed/fjp6j-O4TnI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </center>
                </Carousel.Item>
                <Carousel.Item >
                  <center>

                    <iframe width="80%" height="515" className="vediothree" style={{ marginTop: "10%" }} src="https://www.youtube.com/embed/Dc5L6jT8Ma0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </center>
                </Carousel.Item>
                <Carousel.Item >
                  <center>

                  <iframe width="80%" height="515" className="vediothree" style={{ marginTop: "10%" }} src="https://www.youtube.com/embed/Dox9DpQVAe0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                  </center>
                </Carousel.Item>
              </Carousel> */}

              {/* <img src={Videoa} alt="Image" className="video-container" style={{ width: "80%", height: "80%" }} /> */}
              {/* <video className="" src={Videoa} muted autoPlay loop /> */}



              <center className="socialmedia hee" >
                CONSTRUCTION UPDATE

              </center>

              {/* <Typography className="loc"

                style={{ color: "rgba(214, 180, 86, 1),rgba(236, 213, 113, 1),rgba(188, 141, 55, 1)", fontSize: "50px", fontFamily: "Zilla Slab", marginLeft: "40%", fontWeight: 500, position: "absolute", top: 5, left: 0, }}
              >LOCATION</Typography> */}
              <div >

              </div>


            </div>
          </Box>
        </div>
      </div>






      <div style={{ backgroundColor: "white" }} id="Contact" className="contact-main-con">


        <center>
          <div className="loccont hee"  >
            CONTACT US

          </div>
        </center>



        <div style={{ marginTop: "0%" }} className="container-contact">



          <div className="iframe" >

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3741.2818474826777!2d85.81431798885497!3d20.329971999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909913dc53351%3A0xa89683202473bd42!2sArchid%20Builders%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1683719777651!5m2!1sen!2sin"
              // width="709 "
              width={"100%"}
              height={"380px"}
              allowfullscreen=""
              loading="lazy"
              style={{ marginLeft: "-40%" }}
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="contact-form">
            <h3>GET IN TOUCH WITH US</h3>

            <form>
              <label style={{ fontSize: "15px" }}>
                Name
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ height: "34px", width: "100%" }} type="text" id="inputID" />
              <label style={{ fontSize: "15px" }}>
                Contact No.
              </label>
              <input
                value={number}
                type="number"
                onChange={(e) => setNumber(e.target.value)} style={{ height: "34px", width: "100%" }} id="inputID" />
              <label style={{ fontSize: "15px" }}>
                Email id
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)} style={{ height: "34px", width: "100%" }} type="text" id="inputID" />
              <label style={{ fontSize: "15px" }}>
                Message
              </label>
              <input
                value={messages}
                onChange={(e) => setMessages(e.target.value)}
                style={{ height: "68px", width: "100%" }} type="text" id="inputID" />
              <Button
                onClick={() => {

                  if (
                    number !== "" &&
                    email !== "" &&
                    name !== "" &&
                    messages !== "" &&
                    email.match(
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    ) &&
                    number.match(/^\d{10}$/)
                  ) {
                    handleSubmit(name, number, email, messages);
                    setModal4(true)
                    setName(" ");
                    setEmail(" ");
                    setNumber(" ");
                    setMessages(" ");
                  }
                  // modal3 === true ? setModal3(false) : setModal3(true);
                }}
                style={{ width: '103px', height: '33px', marginTop: "6%", backgroundColor: "#0A253A", fontSize: "15px", alignItems: "center", display: "flex" }}
                variant="contained"
              >Submit</Button>
            </form>
          </div>




        </div>
      </div>

      <div className="footer-location-mobile" style={{ backgroundColor: "#464242", marginTop: "-6%" }}>
        <div className="contact-phn">
          <img style={{ display: "flex", flexDirection: "row", marginLeft: "7%", width: "40px", height: "40px" }} src={map} alt="Loading..." />
          <Typography className="con-text" style={{ maxWidth: "588px", width: "100%", fontSize: "16px", lineHeight: "19px", color: "white" }}>
            Archid Builders Pvt. Ltd. <br />
            Plot No:315, “Archid Central” Fourth Floor, Behind BPCL Petrol Pump
            District Center, Chandrashekherpur Bhubaneswar-751016 Odisha

          </Typography>
        </div>
      </div>







    </div>

  );

}

export default LandingScreen;