import { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Communities from "./pages/Communities/Communities.js";
import AOS from "aos";
import Drawer from "./components/Navbar/Navbar";
import Landing from "./pages/Landing";
import Footer from "./components/footer";





function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      {/* <Drawer /> */}
     

      <Routes>
        <Route exact path="/" element={<Landing />} />

        
        {/* <Route
          exact path="/communities" element={
            <div>
              <Communities/>
             
              
            </div>
          }
        /> */}
      
      </Routes> 
       {/* <Banner /> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
