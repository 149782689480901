import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Style from "../..//pages/Landing/socialmedia.css";





const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1050 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  desktops: {
    breakpoint: { max: 1050, min: 1024 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 650 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
      breakpoint: { max: 650, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};







class indexsocialmedia extends Component {




  render() {
    return (
      <div className='social-carousel'>
         <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-50-px"
            >
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/JPXo3Tg4Gow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/fjp6j-O4TnI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/Dc5L6jT8Ma0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/fjp6j-O4TnI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/JPXo3Tg4Gow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div>
               
            <iframe width="80%" height="300" className="vedioone social-vdo" style={{ marginTop: "24%",marginBottom:"15%" }} src="https://www.youtube.com/embed/Dox9DpQVAe0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>


            
        </Carousel>
      </div>
    );
  }
}

export default indexsocialmedia;