import { useRef, useState } from "react"

import formImage from "../images/formImage.svg"
import "./index.css"
import Confirmation from "../.././confirmationSnackbar"
import Modal from "react-modal";
import { Tick } from 'react-crude-animated-tick';
import map from "../../../images/completed/map.png";
import { Typography, Card, Grid } from "@mui/material";


async function handleSubmit(number, email, requirement) {

  try {
    let res = await fetch("https://dpsc-370710.el.r.appspot.com/add_inquiry", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "Application/json",
      },
      body: JSON.stringify({
        number: number,
        email: email,
        requirement: requirement,
      }),
    });
    let resJson = await res.json();
    console.log(resJson)
    if (resJson.status) {

    } else {

    }
  } catch (err) {
    console.log(err);
  }
};


const ContactUsForm = () => {
  const name = useRef(null)
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [requirement, setRequirement] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false)

  const [modal3, setModal3] = useState(false);

  const customStyles2 = {
    content: {
      top: "45%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "15px",
      height: "300px",
      zIndex: "200",
    },
  };

  const handleClick = () => {
    setOpen(true);
  };


  return (

    <div className="footer-location-web">
      <div style={{ marginBottom: "10%" }} className="contact-phn">
        <img style={{ display: "flex", flexDirection: "row", marginLeft: "35%", width: "40px", height: "40px" }} src={map} alt="Loading..." />
        <Typography className="con-text" style={{ maxWidth: "588px", width: "100%", fontSize: "16px", lineHeight: "19px", color: "rgba(255, 255, 255, 0.8)" }}>
          Archid Builders Pvt. Ltd. <br />
          Plot No:315, “Archid Central” Fourth Floor, Behind BPCL Petrol Pump<br/>
          District Center, Chandrashekherpur Bhubaneswar-751016 Odisha

        </Typography>
      </div>



      {/* <form className="contact-us-form"  > */}
      {/* <div className="form-heading ">
          <div >GET IN TOUCH</div>
          <div className="form-line">
          </div>
        </div> */}
      {/* <div className="form-input-container">
          <div className="contact-us-form-label">Contact Number</div>
     
          <input
            type="number"
            value={number}
            required
            maxLength="10"
            minLength="10"
        
            onChange={(e) => setNumber(e.target.value)}
          />
        </div> */}
      {/* <div >
          <div className="contact-us-form-label">Email</div>
        
          <input
            type="email"
            class="form-control"
            id="basic-url"
            style={{ borderColor: "black" }}
            aria-describedby="basic-addon3"
            required
            value={email}

            onChange={(e) => setEmail(e.target.value)}
          />
        </div> */}
      {/* <div >
          <div className="contact-us-form-label" >Requirement</div>
        
          <textarea
            type="textarea"
            class="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
            style={{ height: "80px", borderColor: "black",width:"100%",marginRight:35 }}
            required
            value={requirement}
            onChange={(e) => setRequirement(e.target.value)}
          />
        </div> */}

      {/* <button

        
           className="form-button"


          onClick={() => {
       
            if(number!==""&&email!=="",requirement!==""&&
            email.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            ) &&
            number.match(/^\d{10}$/)){
              handleSubmit(number,email,requirement)
              setModal3(true)
              setNumber("")
              setEmail("")
              setRequirement("")
          }
            
          }}



        >Submit</button> */}

      {/* </form> */}
      {/* <img className="footer-form-image " src={formImage} alt="Loading..." /> */}

      {/* <Modal isOpen={modal3} style={customStyles2} contentLabel="My dialog">


        <i
          class="fa-solid fa-xmark"
          style={{ fontSize: "20px", paddingLeft: " 53rem", paddingTop: "1rem" }}
          onClick={() => setModal3(false)}
        ></i>

        <div>
          <Tick size={130} />
        </div>



        <h3 className="black-text" style={{ textAlign: "center" }}>
          Submitted Sucessfully
        </h3>
        <h3 className="black-text" style={{ textAlign: "center" }}>
          We will Contact you soon..
        </h3>


      </Modal> */}
      {/* <Confirmation open={open} setOpen={setOpen} /> */}

    </div >


  );
}

export default ContactUsForm;