import React from "react";
import "./index.css";
import FooterImageSection from "./footerImagesection";
import Location from "./location";
import ContactUsForm from "./contactUsForm";
import Footerimage from '../../../src/images/footerimage.svg'

const Footer = () => {
  const myStyle={
    backgroundImage: `url(${ Footerimage})` ,
    
    
};
  return (
  
      <div className="footer-container" style={myStyle} >
        <FooterImageSection />
        
        <Location />
        <ContactUsForm />
      </div>
    
    

  );
};

export default Footer;
