import { useState } from "react";
import gps from "../images/gps.svg";
import mail from "../images/mail.svg";
import phone from "../images/phone.svg";
import { Typography, Card, Grid } from "@mui/material";
import instagram from "../images/instagram.svg";
import facebook from "../images/facebook.svg";
import twitter from "../images/twitter.svg";
// import backk from "../../../images/completed/backk.svg"

// import alogo from "../../../images/Company_Logo/alogo.png"

import "./index.css";

const Location = () => {
  const [modal, setModal] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "400px",
      height: "500px",
      zIndex: "200",

    },
  };
  return (
   < div className="location">
    <div>
      

      {/* <div className="map-button"  onClick={() => {
        modal === true ? setModal(false) : setModal(true);
      }}>
        <img src={locationIcon} alt="Loading..." />
        <div>
          Map
        </div>
      </div> */}
     
      <div className="contact-phn">
        <img style={{ height: "20px", width: "20px", flexDirection: "row" }} src={mail} alt="Loading..." />
        <Typography className="co" style={{ fontSize: "16px", fontFamily: "Inter", marginLeft: "5%" }}>archidhomes@gmail.com</Typography>
      </div>
      <div className="contact-phn">
        <img style={{ height: "20px", width: "20px", flexDirection: "row" }} src={phone} alt="Loading..." />
        <Typography className="co"  style={{ fontSize: "16px", fontFamily: "Inter", marginLeft: "5%" }}>91 7537 000 001</Typography>
      </div>
      <div className="contact-phn">
        <img style={{ height: "20px", width: "20px", flexDirection: "row" }} src={phone} alt="Loading..." />
        <Typography className="co"  style={{ fontSize: "16px", fontFamily: "Inter", marginLeft: "5%" }}>+91 7381 040 001</Typography>
      </div>
      <div style={{ flexDirection: "row", marginTop: "10%",marginLeft:"0%" }}>
      <a target="_blanck" href="https://www.instagram.com/archidbuilders_homesforlife/?igshid=8vhwlhruxu5e">
        <img style={{ height: "25px", width: "25px", flexDirection: "row" }} src={instagram} alt="Loading..." />
        </a>
        <a target="_blanck" href="https://www.facebook.com/archidbuildershomesforlife/">
        <img style={{ height: "25px", width: "25px", flexDirection: "row", marginLeft: "8%" }} src={facebook} alt="Loading..." />
        </a>
        <a target="_blanck" href="https://twitter.com/ArchidLtd">
        <img style={{ height: "25px", width: "25px", flexDirection: "row", marginLeft: "8%" }} src={twitter} alt="Loading..." />
      </a>
      </div>
      <div style={{marginLeft:"-18%",marginTop:"5%"}}>
      <Typography style={{ fontSize: "13px", fontFamily: "Inter", marginLeft: "14%" }}>Copyright © 2021 by Archid Homes. All Rights Reserved.</Typography>
      </div>






      {/* <Modal
       

        isOpen={modal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form className="">
          <div className="d-flex justify-content-between">
            <h3 className="black-text">Location</h3>
            <i
              class="fa-solid fa-xmark"
              style={{ marginLeft: "14rem", fontSize: "30px" }}
              onClick={() => setModal(false)}
            ></i>
          </div>
          <iframe
              width="100%"
              height="400"
              target="_blank"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Archid%20Builders%20Pvt.%20Ltd.%20Plot%20No:315,%20%E2%80%9CArchid%20Central%E2%80%9D%20Fourth%20Floor,%20Behind%20BPCL%20Petrol%20Pump%20District%20Center,%20Chandrashekherpur%20Bhubaneswar-751016%20Odisha&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
        </form>
      </Modal> */}
    </div>
    </div>

  );
};

export default Location;
